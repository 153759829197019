import { useTranslation } from '@/utils/hook'
import Link from 'next/link'
import Image from 'next/image'

const navigation = {
  product: [
    { name: 'Templates', href: '/templates' },
    { name: 'Integrations', href: '/integrations' },
    { name: 'Pricing', href: '/pricing' },
    { name: "What's new ✨", href: 'https://docs.heyform.net/changelog' },
    { name: 'Request a feature', href: 'https://form.canny.io/requests' }
  ],
  resources: [
    { name: 'Help Center', href: 'https://docs.heyform.net' },
    { name: 'Open Source', href: 'https://github.com/heyform/heyform' },
    { name: 'Community', href: 'https://discord.gg/sgT4v4GSTe' },
    { name: 'Blog', href: '/blog' }
  ],
  company: [
    { name: 'About', href: '/about-us' },
    { name: 'Contact Us', href: '/contact' },
    { name: 'Climate Pledge', href: 'https://climate.stripe.com/Vxe5z7' }
  ],
  legal: [
    {
      name: 'Privacy Privacy',
      href: 'https://heyform.net/help/privacy-policy'
    },
    {
      name: 'Terms of Service',
      href: 'https://heyform.net/help/terms-conditions'
    },
    { name: 'Report Abuse', href: 'https://heyform.net/f/E4MKK2hx' }
  ],
  compare: [
    {
      name: 'Typeform Alternative',
      href: 'https://heyform.net/blog/vs-typeform'
    },
    {
      name: 'Google Forms Alternative',
      href: 'https://heyform.net/blog/vs-google-forms'
    }
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/HeyformHQ/',
      icon: props => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      )
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/HeyformHQ',
      icon: props => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      )
    },

    {
      name: 'Linkedin',
      href: 'https://www.linkedin.com/company/heyform/',
      icon: props => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
        </svg>
      )
    },

    {
      name: 'Github',
      href: 'https://github.com/heyform/heyform',
      icon: props => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          {...props}
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M5.315 2.1c.791 -.113 1.9 .145 3.333 .966l.272 .161l.16 .1l.397 -.083a13.3 13.3 0 0 1 4.59 -.08l.456 .08l.396 .083l.161 -.1c1.385 -.84 2.487 -1.17 3.322 -1.148l.164 .008l.147 .017l.076 .014l.05 .011l.144 .047a1 1 0 0 1 .53 .514a5.2 5.2 0 0 1 .397 2.91l-.047 .267l-.046 .196l.123 .163c.574 .795 .93 1.728 1.03 2.707l.023 .295l.007 .272c0 3.855 -1.659 5.883 -4.644 6.68l-.245 .061l-.132 .029l.014 .161l.008 .157l.004 .365l-.002 .213l-.003 3.834a1 1 0 0 1 -.883 .993l-.117 .007h-6a1 1 0 0 1 -.993 -.883l-.007 -.117v-.734c-1.818 .26 -3.03 -.424 -4.11 -1.878l-.535 -.766c-.28 -.396 -.455 -.579 -.589 -.644l-.048 -.019a1 1 0 0 1 .564 -1.918c.642 .188 1.074 .568 1.57 1.239l.538 .769c.76 1.079 1.36 1.459 2.609 1.191l.001 -.678l-.018 -.168a5.03 5.03 0 0 1 -.021 -.824l.017 -.185l.019 -.12l-.108 -.024c-2.976 -.71 -4.703 -2.573 -4.875 -6.139l-.01 -.31l-.004 -.292a5.6 5.6 0 0 1 .908 -3.051l.152 -.222l.122 -.163l-.045 -.196a5.2 5.2 0 0 1 .145 -2.642l.1 -.282l.106 -.253a1 1 0 0 1 .529 -.514l.144 -.047l.154 -.03z" />
        </svg>
      )
    }
  ]
}

export function Footer() {
  const t = useTranslation()
  return (
    <footer aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:pt-24 lg:px-8">
        <div className="xl:grid xl:grid-cols-5 xl:gap-8">
          <div className="space-y-6 xl:col-span-1">
            <Image
              className="h-10"
              src="/static/logo.svg"
              alt="HeyForm"
              width={149}
              height={40}
            />
            <p className="text-slate-900 text-base">
              {t('home.bannerHeadline')}
            </p>
            <div>
              <a
                href="https://trendshift.io/repositories/9025"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src="/static/tod.svg"
                  alt="heyform"
                  width={250}
                  height={55}
                  className="w-[250px] h-[55px]"
                />
              </a>
            </div>
          </div>
          <div className="xl:place-self-end mt-12 grid md:grid-cols-4 gap-8 xl:mt-0 xl:col-span-4">
            <div className="md:grid gap-8">
              <div>
                <h3 className="text-sm font-bold text-slate-900 tracking-wider uppercase">
                  Product
                </h3>
                <ul className="mt-4 space-y-2">
                  {navigation.product.map(item => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-slate-800 hover:underline"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-bold text-slate-900 tracking-wider uppercase">
                  Resources
                </h3>
                <ul className="mt-4 space-y-2">
                  {navigation.resources.map(item => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-slate-800 hover:underline"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-1 md:gap-8">
              <div>
                <h3 className="text-sm font-bold text-slate-900 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-2">
                  {navigation.company.map(item => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-slate-800 hover:underline"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
                <h3 className="text-sm font-bold text-slate-900 tracking-wider uppercase mt-12">
                  Legal
                </h3>
                <ul className="mt-4 space-y-2">
                  {navigation.legal.map(item => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-slate-800 hover:underline"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-1 md:gap-8">
              <div>
                <h3 className="text-sm font-bold text-slate-900 tracking-wider uppercase">
                  Compare
                </h3>
                <ul className="mt-4 space-y-2">
                  {navigation.compare.map(item => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-slate-800 hover:underline"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="items-center max-w-7xl mt-16 flex max-w-7xl flex-col items-center justify-between justify-between md:flex-row">
          <div className="mt-16 mb-8 flex flex-col items-center gap-4 text-center md:flex-row md:text-left">
            <div className="flex items-center">
              <div className="relative rounded-full border-2 border-white dark:border-[#111]">
                <Image
                  src="/static/team-1.jpg"
                  alt="Avatar for Luo"
                  width={32}
                  height={32}
                  className="rounded-full"
                />
              </div>
              <div className="relative -ml-2.5 rounded-full border-2 border-white dark:border-[#111]">
                <Image
                  src="/static/team-3.jpg"
                  alt="Avatar for Wu Qin"
                  width={32}
                  height={32}
                  className="rounded-full"
                />
              </div>
            </div>
            <p className="max-w-md">
              <b className="font-semibold">
                Made by{' '}
                <Link
                  className="underline text-slate-900"
                  href="https://earlybird.im"
                >
                  EarlyBird
                </Link>
              </b>
              , a micro SaaS company. <br />
              brought to you by{' '}
              <a
                href="https://x.com/LuoBaishun"
                className="font-medium underline text-slate-900"
              >
                Luo Baishun
              </a>{' '}
              &amp;{' '}
              <a
                href="https://x.com/mufeng_me"
                className="font-medium underline text-slate-900"
              >
                Wu Qin
              </a>
              .
            </p>
          </div>

          <div className="flex flex-wrap flex-nowrap justify-center gap-1 md:justify-start md:gap-2">
            <div className="flex space-x-6">
              {navigation.social.map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-slate-700 hover:text-slate-900"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
