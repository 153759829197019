import { Flex } from '@/components/ui'
import styled from 'styled-components'

const CONTACT_US_FORM_URL = `${process.env.NEXT_PUBLIC_HOMEPAGE_URI}/f/${process.env.NEXT_PUBLIC_CONTACT_FORM_ID}`

export function NotFoundError(): JSX.Element {
  return (
    <Container>
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <Heading>UH OH! You are lost.</Heading>
        <Description>The page you are looking for no longer exist.</Description>
        <BackHomeContainer justify="center">
          <BackHomeLink href="/">Back to homepage</BackHomeLink>
        </BackHomeContainer>
        <ContactUs>
          Having trouble? <a href={CONTACT_US_FORM_URL}>Send us a message</a>
        </ContactUs>
      </div>
    </Container>
  )
}

export function InternalServerError(): JSX.Element {
  return (
    <Container>
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <Heading>Oops, something went wrong</Heading>
        <Description>
          An server error 500 occurred. Apologize for the inconvenience, and we
          are fixing the problem actively. Please come back later.
        </Description>
        <BackHomeContainer justify="center">
          <BackHomeLink href="/">Back to homepage</BackHomeLink>
        </BackHomeContainer>
        <ContactUs>
          Having trouble? <a href={CONTACT_US_FORM_URL}>Send us a message</a>
        </ContactUs>
      </div>
    </Container>
  )
}

const Container = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 80px);

    @media only screen and (max-width: 800px) {
      height: calc(100vh - 60px);
    }
  }
`

const Heading = styled.div`
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 60px;
  line-height: 1.22;
  color: #000;
  text-align: center;

  @media only screen and (max-width: 1280px) {
    font-size: 40px;
    line-height: 1.4;
  }

  @media only screen and (max-width: 800px) {
    width: 100%;
    margin-bottom: 18px;
    font-size: 32px;
    line-height: 1.4;
  }
`

const Description = styled.div`
  width: 730px;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 35px;
  color: rgb(0, 0, 0);
  text-align: center;

  @media only screen and (max-width: 800px) {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 36px;
  }
`

const BackHomeContainer = styled(Flex)`
  width: 100%;
`

const BackHomeLink = styled.a`
  width: 208px;
  height: 58px;
  color: rgb(255, 255, 255);
  background: rgb(16, 107, 243);
  border-radius: 4px;
  line-height: 58px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.2px;

  @media only screen and (max-width: 800px) {
    display: block;
    width: 100%;
    height: 44px;
    line-height: 44px;
  }
`

const ContactUs = styled.div`
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  letter-spacing: 0.2px;
  margin-top: 24px;

  &,
  a {
    color: rgb(0, 0, 0);
  }

  a {
    text-decoration: underline;
  }
`
