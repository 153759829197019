export default {
  common: {
    product: 'Product',
    features: 'Features',
    useCase: 'Use Case',
    events: 'Event Registration',
    polls: 'Polls',
    quizzes: 'Online Quizzes',
    surveys: 'Surveys',
    integrations: 'Integrations',
    templates: 'Templates',
    pricing: 'Pricing',
    comparison: 'Comparison',
    blog: 'Blog',
    login: 'Log in',
    signup: 'Sign up',
    tryFree: 'Get started',
    findPlan: 'Find your plan',
    useTemplate: 'Use this template',
    userStoryHead: 'User Story',
    resources: 'Resources',
    watchdemo: 'Watch a Demo'
  },
  metadata: {
    home: {
      title: 'HeyForm: the Best Open-Source Form Builder',
      description:
        "Create surveys, quizzes, and polls with HeyForm's free form builder. Sign up now to craft beautiful, interactive online forms easily and quickly for FREE."
    },
    aboutUs: {
      title: 'About Us | Get to Know HeyForm',
      description:
        "Learn more about HeyForm, our mission, and how we're revolutionizing the way you create forms. Join us on our journey!"
    },
    contact: {
      title: 'Get in Touch with Us | HeyForm',
      description:
        "Have questions or need support? Contact the HeyForm team today. We're here to help you with all your form-building needs."
    },
    pricing: {
      title: 'HeyForm Pricing & Plans | Compare Plans & Choose the Best',
      description:
        "Explore HeyForm's pricing plans, compare features, and find the perfect fit. Get answers to FAQs on our Pricing & Plans page."
    }
  },
  footer: {
    resources: 'Resources',
    gettingStarted: 'Getting Started',
    helpCenter: 'Help Center',
    privacy: 'Privacy Policy',
    terms: 'Terms & Conditions',
    company: 'Company',
    about: 'About',
    faq: 'FAQ',
    contact: 'Contact',
    stayConnected: 'Stay Connected',
    changelog: 'Changelog',
    Community: 'Community',
    Climate: 'Climate Pledge'
  },
  home: {
    bannerHeadline: 'Create intelligent forms that drive engagements',
    bannerDesc:
      'Simplify data collection, engage users, and achieve meaningful results - all while staying budget-friendly.',
    bannerSocialProof: 'Daily choice of {count} happy users',
    usageTitle: 'It gets the job done and saves you time',
    usageDesc:
      'Creating a form has never been easier with our ready-made template, suitable for all roles and ages.',
    usageCreators: 'Creators',
    usageCreators1: 'Contact form',
    usageCreators2: 'Customer feedback survey',
    usageCreators3: 'Workshop Registration Form',
    usageProduct: 'Product',
    usageProduct1: 'Product feedback survey',
    usageProduct2: 'Product registration form',
    usageProduct3: 'Product order form',
    usageMarketing: 'Marketing',
    usageMarketing1: 'Free trial form',
    usageMarketing2: 'Lead capture form',
    usageMarketing3: 'Referral form',
    usageHR: 'HR',
    usageHR1: 'Exit interview form',
    usageHR2: 'Job application form',
    usageHR3: 'Employee satisfaction survey',
    usageSupport: 'Customer Support',
    usageSupport1: 'Client satisfaction survey',
    usageSupport2: 'Brand survey form',
    usageSupport3: 'Customer testimonials form',
    usageEducation: 'Education',
    usageEducation1: 'Student enrollment form',
    usageEducation2: 'Teacher feedback form',
    usageEducation3: 'Student survey form',
    usageCTA: 'Browse all templates',
    brandHeadline: 'Trusted by brands spanning different industries',
    testimonialTitle: 'People ❤️ HeyForm',
    testimonialDesc:
      'HeyForm is the complete online form builder, designed for the modern user. A better way to ask the right questions and gather data for your business.'
  },
  product: {
    bannerHeadline: 'Create forms users love to complete',
    bannerSubheadline:
      'Create UX-first quizzes, polls, surveys, and events with our people-friendly form builder—no coding required!',
    featureHeadline: "It's simple, but it just works great!",
    integrationsHeadline: 'It’s ready to sync with what you use',
    integrationSubheading:
      'Streamline your workflow with seamless integration of your everyday tools.',
    integrations: [
      {
        title: 'Slack'
      },
      {
        title: 'Airtable'
      },
      {
        title: 'Zapier'
      },
      {
        title: 'WordPress'
      },
      {
        title: 'HubSpot'
      },
      {
        title: 'Dropbox'
      },
      {
        title: 'Mailchimp'
      },
      {
        title: 'Monday'
      },
      {
        title: 'Telegram'
      },
      {
        title: 'Google Drive'
      },
      {
        title: 'Google Analytics'
      },
      {
        title: 'Lark Suite'
      }
    ],
    integrationButtom: 'Check out all the integrations',
    ctaHeading: "If you don't know, don't guess.",
    ctaSubheading:
      'Create a HeyForm, and ask your audience for the answers you need.'
  },
  integrations: {
    bannerHeadline: 'Connect your form with your favorite tools',
    bannerText:
      'Our online form builder makes your life more productive with the best selection of integrations such as MailChimp, Airtable, Google Sheets, and many more.'
  },
  blog: {
    bannerHeadline: 'Make better forms',
    bannerText: 'Things we find interesting, our learning and our expertise.'
  },
  pricing: {
    questionsHeadLine: 'Frequently Asked Questions',
    questionsSubhead: 'Haven’t found what you’re looking for? Try the',
    questionsA: 'Help Center',
    questionsA2: 'Contact us',
    questions: [
      {
        title: 'What payment methods do you accept?',
        answer:
          'We accept credit cards and debit cards via Stripe. Bank transfer is also supported by contacting our support.'
      },
      {
        title: 'Is HeyForm really free?',
        answer:
          'Unlike some of the competitors, HeyForm provides a real free plan without time limit. Once you sign up, you automatically subscribe to our Free plan.'
      },
      {
        title: 'Can I upgrade my plan anytime?',
        answer:
          'Yes! You can upgrade your plan anytime you want, our billing system will calculate the price gap automatically, so you just need to simply pay the new invoice to complete the upgrade.'
      },
      {
        title: 'Can I belong to multiple teams on HeyForm?',
        answer:
          'Yes! You can use the same email address to create and join multiple teams. However, each one of these workspaces is on its own plan and needs to be upgraded individually.'
      }
    ]
  },
  aboutUs: {
    bannerHeadline: 'Our Story',
    bannerText: 'This is why we’re building HeyForm.',
    teamHeadline: 'Meet the Minds Behind HeyForm',
    teamSubhead: 'The Brains, the Code, the AI Agents, and maybe You?',
    members: [
      {
        name: 'Luo Baishun',
        position: 'Co-founder'
      },
      {
        name: 'Wu Qin',
        position: 'Co-founder'
      },
      {
        name: 'ChatGPT',
        position: 'Chief Executive Officer'
      }
    ],
    joinusHeadline: 'Join Our Growing Team',
    joinussubHead:
      'We are looking for active brains and good souls. Looking for a great place to deliver your best work? We might just have a workstation with your name on it!',
    joinusA: 'Join us, make a change '
  },
  contact: {
    bannerHeadline: 'Get in touch',
    bannerSubhead:
      'Questions? Feedback? Just want to say hi? Fill out the form below, and we’ll get back to you soon!'
  },
  templates: {
    bannerHeadline: ['Free form templates for all your needs'],
    bannerSubhead:
      'Explore and customize from our wide range of templates tailored to meet all your form and survey needs.'
  }
}
