export default {
  common: {
    product: '产品',
    features: '功能',
    useCase: '使用场景',
    events: '活动报名',
    polls: '在线投票',
    quizzes: '在线测验',
    surveys: '问卷调查',
    integrations: '应用集成',
    templates: '样板',
    pricing: '价格',
    blog: '博客',
    login: '登录',
    tryFree: '免费试用',
    useTemplate: '使用此样板',
    userStoryHead: '用户故事'
  },
  metadata: {
    home: {
      title: 'HeyForm: The best open-source form builder',
      description:
        'HeyForm is an easy-to-use drag & drop form maker capable of crafting powerful online forms. Use HeyForm to create beautiful, interactive free online forms.'
    },
    aboutUs: {
      title: 'About Us | HeyForm',
      description:
        'HeyForm is the complete online form builder, designed for the modern user. A better way to ask the right questions and gather data for your business.'
    },
    contact: {
      title: 'Contact Us | HeyForm',
      description:
        'Do not hesitate to contact us, our teams will answer you as soon as possible!'
    },
    pricing: {
      title: 'Plans & Pricing | HeyForm',
      description:
        'HeyForm provides discounts for educational institutions and nonprofit organizations around the world. HeyForm templates include online feedback form for students, self evaluation form for teachers, signup form templates, self assessment form for employees, sample job application forms and many other form templates.'
    },
    product: {
      title: 'Powerful Online Form Creator Free For Everyone | HeyForm',
      description:
        'Create smart online registration forms, feedback forms, surveys, questionnaires, and much more with HeyForm. No coding skills are needed. Start for free.'
    }
  },
  footer: {
    resources: '资源库',
    gettingStarted: '入门指引',
    helpCenter: '帮助中心',
    privacy: '隐私政策',
    terms: '服务条款与条件',
    company: '公司',
    about: '关于我们',
    faq: '常见问题',
    contact: '联络',
    stayConnected: '关注我们',
    changelog: '更新日志',
    Community: '社区',
    Climate: 'Climate Pledge'
  },
  home: {
    bannerHeadline: '在短短数分钟内创建、设计和部署在线表单',
    bannerDesc:
      '从众多现成的样板中挑选出符合您需求的，轻松开展数据收集，并根据最终您客户的需求作出正确的决策。',
    bannerTips: '无需登记信用卡即可开始，且免费版本无任何使用时间限制。',
    brandHeadline: '受到各行各业品牌的信赖',
    brandSubtitle: '阅读客户案例',
    featuresKnowmore: '了解详情',
    features: [
      {
        title: '更聪明的提出问题',
        text: '不得不拖拽一切的日子一去不复返了。我们想让你的生活更轻松。更新、更快、更智能的HeyForm让你比以往更有效地制作调查问卷、申请表、测验、投票等。还在等什么？点击进行免费测试！'
      },
      {
        title: '收集真实有效的数据',
        text: '简化收集客户信息的流程，减少您的日常管理开销，并使合规流程自动化。在我们的实时数据面板中，您可以轻松追踪到每一个表单的收集进度。'
      },
      {
        title: '集成您最喜爱的工具',
        text: '将您的表单与您最喜爱的第三方效率工具、项目管理工具相集成，轻松实现复杂任务的全自动化，从而简化您的日常工作。'
      }
    ],
    starttemplateHead: '从一个样板开始',
    starttemplateText: '创建在线表单最便捷的方式就是挑选一个现成的样本。查看',
    starttemplateA: '样板库',
    articleHeadline: '最新资讯',
    articleSubheading:
      '关注我们的趋势、更新和见解，学习如何实现数据收集过程的现代化和最优化。',
    moreArticles: '更多文章',
    customer: [
      {
        text: 'First impressions matter and our customers were bored of the usual forms. HeyForm gave life to our website and made forms fun and interactive.',
        jobName: 'Product Designer',
        userName: 'Harold Tyler'
      },
      {
        text: 'HeyForm makes our everyday tasks smoother. The easy-to-use UI and the numerous options to customize, makes it easy to work with. It’s become part of our business.',
        jobName: 'Application & Web Developer',
        userName: 'Julia Armstrong'
      },
      {
        text: 'HeyForm helped us collect and manage data without having to know any coding. We easily made amazing looking forms and launched them well before schedule.',
        jobName: 'Ant Group',
        userName: 'Yajing Zhai'
      }
    ]
  },
  product: {
    bannerHeadline: '自定义您的在线表单，收集反馈，并获得更好的见解',
    bannerSubheadline:
      'HeyForm 为您提供最简单的创建在线表单的方法。使用 HeyForm，您只需要几次鼠标单击，无需任何编程或设计技巧，即可完成表单创建和数据收集工作。',
    featureHeadline: '基础功能',
    features: [
      {
        title: '建立有趣的在线表单',
        description:
          '通过制作更具创新性的在线表格、测验和调查，完成更多工作。我们的表单生成器可以让您以更友好的方式提出有效的问题。'
      },
      {
        title: '40 多种组件类型',
        description:
          '用正确的组件，问正确的问题。超过 40 种不同类型的组件，总有符合您需要的一种。'
      },
      {
        title: '字段验证',
        description:
          '在用户填写表单时对内容进行针对性的验证，有效杜绝数据收集过程中的无效信息。'
      },
      {
        title: '导出您的数据',
        description:
          '随时随地将您的表单数据导出为 CSV 格式的文件并下载到您的设备中，您的数据只受您的掌控。'
      },
      {
        title: '多渠道分享',
        description:
          '将您的表单通过多种渠道分享到您的用户手中：网页嵌入、公开链接、二维码……并在控制面板中集中查看统计数据。'
      },
      {
        title: '文件收集',
        description:
          '开启文件上传，向您的用户收集您需要的文件，并与您私有的云存储账户实现数据同步。'
      },
      {
        title: '您的隐私，永远在第一位',
        description:
          '我们非常重视您和您用户的数据隐私，我们遵守严格的数据安全法规，包括 GDPR，以履行我们对您隐私的长期承诺。'
      },
      {
        title: '您的数据安全可靠',
        description:
          '我们采用 256 位的 SSL 证书加密，PCI DSS 级别的数据传输加密，并托管在安全稳定的云基础设施，以确保您的数据安全。'
      }
    ],
    enfeatures: [
      {
        title: 'Get Complete Control & Manage Teams',
        text: ' Design your form exactly how you want it—quickly and easily. Invite team members and outside stakeholders to co-create forms and streamline data collection experiences that meet organizational needs and goals.'
      },
      {
        title: 'Build audience list',
        text: 'Work with multiple teams towards a single goal. Stop wasting time on tedious data entry tasks. Build, manage, and target a diverse set of audience with HeyForm. Add contacts, create groups, and send your online forms easily.'
      }
    ],
    integrationsHeadline: '应用集成',
    integrationSubheading: '将您的表单与您日常使用的工具相集成。',
    integrations: [
      {
        title: 'Slack',
        tex: '在适当的时间，发送通知给正确的人，展开协作。'
      },
      {
        title: 'Airtable',
        tex: '自动同步表单数据到 Airtable 数据库。'
      },
      {
        title: 'Zapier',
        tex: '使用 Zapier 将 HeyForm 和您最喜爱的应用相连接，创建自动化工作流。'
      },
      {
        title: 'WordPress',
        tex: '将 HeyForm 和您的 WordPress 网站整合，获得客户的反馈。'
      },
      {
        title: 'HubSpot',
        tex: '添加新联系人并将数据直接传输到 HubSpot CRM。'
      }
    ],
    integrationButtom: '查看所有应用集成',
    customerHeadline: '来自我们客户的声音'
  },
  integrations: {
    bannerHeadline: '将 HeyForm 与您最喜爱的工具相集成',
    bannerText:
      'HeyForm 提供了诸多能够让您的工作和生活变得更加有效率的应用集成，例如 MailChimp、Airtable、Google Sheets 以及更多。'
  },
  blog: {
    bannerHeadline: '制作更好的表单',
    bannerText: '分享记录我们觉得有趣的事情，我们的学习历程以及我们的专业分享。'
  },
  pricing: {
    bannerHeadline: '定价方案',
    bannerText: '选择最适合您团队需要的计划。',
    free: '免费',
    basic: '基础版',
    pro: '专业版',
    business: '商务版',
    subtextBasic: [
      '不限制提问数量',
      '不限制表单数量',
      '5 GB 云存储',
      '100 联系人',
      '5 写作者',
      '不限制数据提交量'
    ],
    subtextPro: [
      '不限制组件类型',
      '不限制主题与样板',
      '20 GB 云存储',
      '300 联系人',
      '10 协作者',
      '自定义域名'
    ],
    subtextBusiness: [
      '不限制集成类型',
      '专属客户经理',
      '50 GB 云存储',
      '1000 联系人',
      '30 协作者',
      '移除 HeyForm 品牌标识'
    ],
    planButtom: '创建账户',
    planHintext: '每月，按年缴费',
    brandA: '比较所有产品特征',
    contactSubhead: '需要专业定制？获取政企特供版。',
    contactBottom: '与销售专家对话',
    contactText: [
      '为您的内网应用定制集成方案',
      '额外的 API 权限',
      '额外的技术支持',
      '定制化的商业协议',
      '独家体验 BETA 版的产品和功能'
    ],
    contactLink: '仍然不确定？',
    tableHeadLine: '方案对比',
    tableFree: '完全免费！',
    tableGet: '创建账户',
    table: [
      {
        TableHeader: '用量',
        SingleColumn: [
          '价格（年缴）',
          '价格（月缴）',
          '单个表单最大问题数',
          '表单数量限制',
          '联系人数量限制',
          '数据条目数量',
          '协作人数量',
          '附加坐席价格',
          '报告数量',
          '存储空间'
        ]
      },
      {
        TableHeader: '功能特性',
        SingleColumn: [
          '应用集成',
          '字段验证',
          '垃圾信息与机器人防治',
          '访问样板库',
          '嵌入到外部网站',
          '导出数据为 CSV',
          '密码保护',
          '主题定制',
          '自定义致谢页面',
          '到达设定上限后停止收集',
          '定时停止数据收集',
          '自定义完成提交跳转',
          '自定义域名',
          '团队协同',
          '白标',
          '专属客户经理'
        ]
      },
      {
        TableHeader: '后续即将实现的功能',
        SingleColumn: [
          '自定义自动回复',
          '收款功能',
          '逻辑跳转',
          '自定义 Meta 描述',
          '自定义邮件内容',
          '隐藏字段',
          '部分提交',
          '表单链接重写',
          '最大文件尺寸',
          '计算器',
          'API 访问'
        ]
      }
    ],
    tableWord: {
      usd: '美元',
      unlimited: '不限制',
      limited: '部分解锁',
      seat: '/坐席/月',
      unlocked: '解锁',
      partial: '部分解锁',
      commission: '佣金',
      noCommission: '无佣金',
      file: '文件'
    },
    questionsHeadLine: '常见问题解答',
    questionsSubhead: '仍然没有找到您想要的答案？访问',
    questionsA: '帮助中心',
    questionsA2: '联系我们',
    questions: [
      {
        title: '我可以使用什么方式进行付款？',
        answer:
          '我们使用 Stripe 作为支付接口，接受来自信用卡和借记卡的付款。银行转账也是一个支付选项，但您需要提前和我们取得联系。'
      },
      {
        title: 'HeyForm 真的可以免费使用吗？',
        answer:
          '和一些竞品不同，HeyForm 提供了一个完全免费的体验版本，不设任何时间限制。只要您注册成为 HeyForm 的用户，您将自动订阅免费体验版。'
      },
      {
        title: '我可以随时升级我的计划吗？',
        answer:
          '当然！您可以随时升级您的计划，我们的计费系统将会自动计算出差价，只要您完成对应账单的支付，您的计划将会自动完成升级更新。'
      },
      {
        title: '在 HeyForm 中，我可以加入多个工作空间吗？',
        answer:
          '完全没有问题。您可以使用同一邮件地址来创建或加入多个工作空间。但需要提醒您注意的是，每一个工作空间都是独立计费的。 '
      }
    ]
  },
  aboutUs: {
    bannerSubhead:
      'HeyForm 是一套现代化的、完整的低代码在线表单解决方案，引导用户正确开展调研，帮助用户更高效完成数据收集工作。',
    bannerHeadline: '我们的力量来自我们的团队',
    bannerText:
      '我们是一群善于解决问题的极客，我们总是保持积极。我们相信微创新可以创造巨大的价值，复杂的事情简单化是我们企业文化的DNA。我们为实现自我价值而庆祝。',
    teamHeadline: 'HeyForm 背后的团队',
    teamSubhead: '我们的团队成员来自世界各地，这为我们注入了多元的文化。',
    members: [
      {
        name: '罗百顺',
        position: '创始人'
      },
      {
        name: '武琴',
        position: '技术总监'
      },
      {
        name: 'Mighil Puthukkudy',
        position: 'Full-stack Marketer'
      },
      {
        name: '徐倩倩',
        position: '产品助理'
      }
    ],
    valueHeadline: '核心价值',
    valueSubhead:
      '这些价值观驱动着我们的日常决策，发展着我们的人际关系，并指导着我们的战略：',
    spanText: ['保持精益', '保持开放', '保持简单', '保持感恩'],
    joinusHeadline: '加入我们不断增长的团队',
    joinussubHead:
      '我们正在寻找有活力的头脑和善良的灵魂。如果您在寻找一个很棒的地方来兑现您的天赋，我们可能会有一个有您名字的工作站！',
    joinusA: '加入我们，创造不同'
  },
  contact: {
    TopLabel: '联系我们',
    bannerHeadline: '让我们对话吧！',
    bannerSubhead:
      '如果您对我们的产品有任何疑问，或您不太确定应该如何选择产品计划，请随时联系我们的团队，我们可以安排一个电话沟通时间。',
    callUs: '给我们打电话',
    phone: '(302) 513-3637',
    emailUs: '给我们发邮件',
    email: 'support@heyform.net',
    officesHeadline: '我们的国际地址',
    locations: [
      {
        name: '美国',
        address: '30 N. Gould St. Ste R, Sheridan',
        postoffice: 'Sheridan WY 82801, USA'
      },
      {
        name: '中国',
        address: '解放大道 18 号，凯德西城广场 T2 办公楼 20 层',
        postoffice: '湖北武汉'
      },
      {
        name: '印度',
        address: 'InQ Tower, 1st floor, Palarivattom ',
        postoffice: '科钦，喀拉拉邦'
      }
    ],
    footerrHeadline: '创建您自己的专属联络表单',
    footerText:
      '您可以创建一个和我们在本页使用的一样的联络表单，并定制成为符合您的品牌调性的风格样式。',
    footerBottom: '现在开始',
    footerA: '阅读文档'
  },
  templates: {
    bannerHeadline: ['得到启发，', '选择样板，', '定制您的表单。'],
    bannerSubhead: '各种主题的样板均已备好，助力您的业务效率飞速提升！'
  }
}
